.loop_tab_item{
  border-color:#181B1A;
  cursor: pointer;
  padding: 24px 0px;
  font-size: 18px;
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  width: 100%;
  height: 100%;
  color: #5D5F60;
  transition: all 250ms ease-in-out;
}
.loop_tab_item:hover{
  color: white;
}
.loop_tab_item_active{
  border-color: #19FB80 !important;
  color: white;
}

.loop_tab_item_active path{
  fill: #19FB80 !important;
}


.loop-main-container{
  margin: 60px 0px;
}

.loop-info{
  border: 1px dashed #414D48;
  border-radius: 10px;
  padding: 16px;
  font-size: 14px;
  color: white;
}

.loop-info .title{
  font-weight: bold;
}

.loop-input-title{
  color: #B1B1B1;
  font-weight: 300;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
}

.loop-input-title .link-title{
  color: #19FB80;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
}

.no-balance-alert{
  padding: 12px 17px;
  font-size: 16px;
  color: white;
  border-radius: 8px;
  border: 1px solid #464948;
  margin-top: 10px;
}

.no-balance-alert .get-gmi-link{
  color: #19FB80;
  text-decoration: underline;
  cursor: pointer;
}

.border-line{
  border-top: 2px solid #262D2A;
}

.information-card{
  padding: 16px 20px;
  border: 1.5px solid rgba(255, 255, 255, 0.2);
  border-radius: 8px;
}

.information-card .toggler-bar{
  display: flex;
  justify-content: space-between;
  color: white;
  font-size: 16px;
  align-items: center;
  cursor: pointer;
}

.apy-rate{
  border: 1px solid #19FB80;
  font-size: 16px;
  font-weight: 600;
  color: white;
  padding: 4px 10px;
  border-radius: 4px;
}

.information-detail-title{
  font-weight: 600;
  font-size: 16px;
  color: white;
}

.text-loop-primary{
  color: #19FB80 !important; 
}

.information-detail-items{
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.detail-title{
  font-weight: 300;
  font-size: 16px;
  color: #B1B1B1 !important;
}

.detail-value{
  font-weight: 600;
  color: white;
  font-size: 16px;
}

.loop-btn-second{
  background-color: #19FB80 !important;
  border-color: transparent !important;
}
.buy_gmi_input{
  background: #ffffff00 !important;
  border: none !important;
  color: #b1b1b1;
  font-size: 12px;
}
input.buy_gmi_input:focus-visible {
  outline: unset;
}

.max-btn.market{
  font-size: 12px !important;
}

.info-price{
  color: #B1B1B1;
}
.dropdown-btn{
  cursor: pointer;
}

.market-dropdown{
  position: absolute;
  right: 0px;
  top: calc(100% + 5px);
  width: 100%;
  z-index: 9999999;
}

.market-dropdown-item{
  padding: 14px 10px;
  border-radius: 8px;
  cursor: pointer;
}
.market-dropdown-item:hover{
  background: #363B39;
}

.dropdown-item-title{
  font-size: 18px;
}

.dropdown-icon{
  transition: all 150ms ease-in-out;
}

.rotate-180{
  transform: rotate(180deg);
}

.market-dropdown-hero{
  border: 1px solid transparent;
  transition: all 150ms ease-in-out;
}

.market-dropdown-hero.active{
  border: 1px solid #19FB80;
}

.wheel-range{
  position: relative;
}

.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 16px;
  border-radius: 5px;
  outline: none;
  opacity: 1;
  background-color: transparent;
  -webkit-transition: .2s;
  transition: opacity .2s;
  position: relative;
  z-index: 99999;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 16px; /* Width of the thumb */
  height: 32px; /* Height of the thumb */
  border-radius: 10px; /* Make the thumb circular */
  background: #213C2E; /* Thumb color */
  cursor: pointer;
  border: 2px solid #19FB80;
  z-index: 9999;
  position: relative;
}

.slider::-moz-range-thumb {
  width: 16px; /* Width of the thumb */
  height: 32px; /* Height of the thumb */
  border-radius: 10px; /* Make the thumb circular */
  background: #213C2E; /* Thumb color */
  cursor: pointer;
  border: 2px solid #19FB80;
  z-index: 9999;
  position: relative;
}

.wheel-range-fill{
  position: absolute;
  height: 16px;
  background-color: #19FB80;
  top: 1px;
  border-radius: 8px;
}
.wheel-range-bg{
  background-color: #18482E;
}

.leverage-value{
  font-size: 16px;
  color: white;
  font-weight: 600;
}

.borrow-health-factor{
  color: #B1B1B1;
  font-size: 16px;
  font-weight: 300;
}

.health-factor-items{
  display: flex;
  gap: 12px;
  margin-top: 15px;
}

.health-factor-item{
  color: white;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  padding: 9px 0px;
  flex: 1;
  border: 1px solid #464948;
  border-radius: 6px;
  cursor: pointer;
  transition: all ease-in-out 150ms;
  display: flex;
  justify-content: center;
  align-items: center;
}
.health-factor-item.active{
  color: #19FB80;
  border-color: #19FB80;
}

.position-item-detail{
  font-weight: 400;
}

.position-information-card{
  background-color: #222725;
  border: 1px solid transparent;
}

.border-line-position{
  border: 1px solid #363B39;
  border-radius: 2px;
}

.cursor-pointer{
  cursor: pointer;
}
.modal-custom-size{
  width: 610px !important;
  max-width: none !important;
}

@media screen and (max-width: 768px) {
  .apy-rate{
    font-size: 12px;
  }

  .dropdown-item-title{
    font-size: 14px !important;
  }
  .info-price{
    font-size: 14px;
  }
  .modal-custom-size{
    width: auto !important;
    max-width: unset !important;
  }
  .detail-title,
  .detail-value{
    font-size: 14px;
  }
}